.scheduler-patients_patient-form-container {
    padding: 0 16px;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.scheduler-patients_patient-form-input {
    width: 49% !important;
}

.scheduler-patients_patient-form-input-small {
    width: 32%;
}

.scheduler-patients_patient-form-invalid-phone-label {
    display: flex;
    align-items: center;
}

.scheduler-patients_patient-form-warning-icon {
    width: 16px;
    height: 16px;
    color: #a77940;
    margin-right: 8px;
    flex-shrink: 0;
}

.scheduler-patients_patient-form-invalid-phone-help-text {
    color: #a77940;
    display: flex;
}