.scheduler-terms-conditions-and-privacy-policy_container {
    font-size: 12px;
    text-align: center;
    font-family: Lato;
    color: #061c3f;
}

.scheduler-privacy-policy_link {
    color: #01b6f5;
}

.scheduler-terms-conditions_link {
    color: #01b6f5;
}