@media (max-width: 620px) {
    .scheduler-forgot-password_container {
        height: 100%;
        padding-top: 96px;
    }
    .scheduler-forgot-password_back-link {
        left: 16px;
        top: 16px;
    }
    .scheduler-forgot-password_card {
        width: 100%;
        border-radius: 0;
        padding: 20px;
        flex-direction: column;
    }
    .scheduler-forgot-password_content {
        border-left: 0;
        padding-left: 0;
        width: 100%;
    }
    .scheduler-forgot-password_header {
        font-size: 20px;
        margin: 8px 0 12px 0;
    }
}
