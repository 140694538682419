.scheduler-privacy-policy_container {
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.scheduler-privacy-policy_header {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    font-family: 'Lato-Light';
    font-size: 24px;
    font-weight: 300;
    color: #061c3f;
    height: 72px;
    border-bottom: 1px solid rgba(160, 160, 188, 0.1);
    box-shadow: 0 0px 1px 0 #e3e5ed;
    background-color: #ffffff;
    position: relative;
}

.scheduler-privacy-policy_back-button {
    position: absolute;
    left: 16px;
    top: 16px;
}

.scheduler-privacy-policy_content {
    font-family: 'Lato-Light';
    font-size: 16px;
    font-weight: 300;
    color: #061c3f;
    padding: 24px 30px;
    height: calc(100% - 110px);
    overflow: auto;
}

.scheduler-privacy-policy_back {
    position: absolute;
    left: 30px;
}

.scheduler-privacy-policy_back-icon {
    margin-right: 8px;
}

@media (max-width: 620px) {
    .scheduler-privacy-policy_back {
        position: absolute;
        left: 12px;
    }
    .scheduler-privacy-policy_header {
        font-size: 18px;
    }
    .scheduler-privacy-policy_content {
        font-size: 14px;
        padding: 24px 16px;
    }
}
