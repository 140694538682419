.shedule-driver-cell_icon-sts {
    width: 14px;
    height: 14px;
    margin-bottom: 2px;
    fill: #009ACF !important;
    color: #009ACF !important;
}

.shedule-driver-cell_badge {
    color: #009ACF !important;
    padding-right: 8px !important;
    padding-left: 8px !important;
}
