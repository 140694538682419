@media (min-width: 1920px) {
    .scheduler-weekly-schedule_header, .scheduler-weekly-schedule_content {
        max-width: 1800px;
        margin: 0 auto;
    }
    
    .scheduler-weekly-schedule_day-column {
        width: 300px;
    }

    .scheduler-weekly-schedule_appointment-footer {
        display: flex;
        justify-content: center;
    }

    .scheduler-weekly-schedule_search {
        width: 300px;
    }
}
