.scheduler-output_label {
    font-size: 13px;
    color: #576574;
    margin-bottom: 2px;
}

.scheduler-output_description {
    font-size: 15px;
    line-height: 1.4;
    color: #061C3F;
}
