.scheduler-not-found_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url(/assets/images/not-found.svg);
    background-position: center;
    background-size: unset;
    background-repeat: no-repeat;
}

.scheduler-not-found_text {
    margin-top: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    text-align: center;
}

.scheduler-not-found_title {
    color: #bbbec1;
    font-weight: 300;
    margin-bottom: 8px;
}

.scheduler-not-found_link {
    color: #01B6F5;
    text-decoration: underline;
}
