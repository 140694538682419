body, html {
    background-color: #fCfdff;
    height: 100%;
}

.scheduler-auth_wrap-container {
    background-color: #f4f6f9;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.scheduler-auth_content-container {
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
}

.scheduler-schedule_wrap-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
}
