.shedule-status-badge_container {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 0 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shedule-status-badge_hover-button {
    margin: 0 8px;
    flex-shrink: 1;
}

.shedule-status-badge_hover-button-icon {
    fill: #576574 !important;
    color: #576574 !important;
}

.shedule-status-badge_select-container {
    width: 100%;
    height: 100%;
    position: relative;

}

.shedule-status-badge_select-container::after {
    content: '';
    position: absolute;
    display: block;
    right: 1.5rem;
    bottom: 45%;
    pointer-events: none;
    width: 0.45rem;
    height: 0.45rem;
    border-style: solid;
    border-width: 0.15em 0.15em 0 0;
    transform: rotate(135deg);
    vertical-align: top;
    color: #01b6f5 !important;
}

.shedule-status-badge_select {
    -moz-appearance: none;
    -webkit-appearance: none;
    width: calc(100% - 0.5rem);
    line-height: 1.5rem;
    height: 1.8rem;
    border: 1px solid #01b6f5 !important;
    border-radius: 1rem;
    padding: 0 0.675rem;
    background-color: white;
    color: #061c3f;
    font-size: 14px;
    transition: all 0.1s linear;
    position: relative;
}

.shedule-status-badge_select:focus, .shedule-status-badge_select:active, .shedule-status-badge_select:visited {
    outline: 0;
    border: 1px #01b6f5 solid;
    box-shadow: 0 0 2px #01b6f5;
}

.shedule-status-badge_icon {
    flex-shrink: 0;
    margin-bottom: 2px;
}

.shedule-status-badge_icon-own-car {
    width: 14px;
    margin-bottom: 2px;
}

.shedule-status-badge_icon-no-show {
    width: 14px;
    margin-bottom: 2px;
}

.shedule-status-badge_icon-sts {
    width: 14px;
    height: 14px;
    margin-bottom: 2px;
    fill: #009ACF !important;
    color: #009ACF !important;
}

.shedule-status-badge_processing {
    color: #576574 !important;
    padding-left: 6px !important;
    max-width: 120px;
}

.shedule-status-badge_verified {
    color: #1de9b6 !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
    max-width: 120px;
}

.shedule-status-badge_expired {
    color: #fe4849 !important;
    padding-right: 6px !important;
    padding-left: 8px !important;
    max-width: 120px;
}

.shedule-status-badge_own-car {
    color: #01B6F5 !important;
    padding-right: 6px !important;
    padding-left: 6px !important;
    max-width: 120px;
}

.shedule-status-badge_no-show {
    color: #a0a0bc !important;
    padding-right: 6px !important;
    padding-left: 6px !important;
    max-width: 120px;
}

.shedule-status-badge_sts {
    color: #009ACF !important;
    padding-right: 10px !important;
    padding-left: 6px !important;
}

.shedule-status-badge_kept-app {
    color: #5C56B6 !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    max-width: 120px;
}
