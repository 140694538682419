.scheduler-terms-conditions-and-privacy-policy_container {
    font-size: 12px;
    text-align: center;
    font-family: Lato;
    color: #061c3f;
}

.scheduler-privacy-policy_link {
    color: #01b6f5;
}

.scheduler-terms-conditions_link {
    color: #01b6f5;
}
.scheduler-signin_container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
    padding-top: 40px;
    height: 100vh;
}

.scheduler-signin_logo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scheduler-signin_image {
    height: 64px;
}

.scheduler-signin_header {
    font-family: Lato;
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    color: #061C3F;
    margin: 14px 0 0 0;
}

.scheduler-signin_location-name {
    font-size: 16px;
    color: #576574;
    margin: 4px 0 12px 0;
    font-weight: 300;
    line-height: 1.5;
}

.scheduler-signin_card {
    display: flex;
    align-items: center;
    padding: 32px 40px;
    width: 420px;
    margin-bottom: 16px;
    height: -webkit-max-content;
    height: max-content;
    min-height: 320px;
}

.scheduler-signin_inputs-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.scheduler-signin_inputs-container > :not(:first-child) {
    margin-top: 24px;
}

.scheduler-signin_inputs-container > :last-child {
    margin-top: 16px;
}

.scheduler-signin_link {
    color: #01b6f5;
    text-align: center;
    font-size: 1rem;
}

.scheduler-signin_language-selector {
    position: absolute;
    top: 20px;
    right: 20px;
}

@media (max-width: 750px) {
    .scheduler-signup_card {
        width: 600px;
    }
}

@media (max-width: 620px) {
    .scheduler-signin_container {
        height: 100%;
        padding-top: 96px;
    }
    .scheduler-signin_card {
        width: 100%;
        border-radius: 0;
        padding: 20px;
        flex-direction: column;
    }
    .scheduler-signin_inputs-container {
        border-left: 0;
        padding-left: 0;
        width: 100%;
    }
    .scheduler-signin_header {
        font-size: 20px;
        margin: 8px 0 12px 0;
    }
}

.scheduler-back-button {
    align-self: flex-start;
    margin-bottom: 12px;
}

.scheduler-back-button_icon {
    margin-right: 8px;
    width: 16px;
    height: 16px;
}

.scheduler-forgot-password_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 16px;
    padding-top: 16px;
    height: 100vh;
    position: relative;
}

.scheduler-forgot-password_back-button {
    position: absolute;
    top: 20px;
    left: 16px;
}

.scheduler-forgot-password_image {
    height: 54px;
    width: 54px;
}

.scheduler-forgot-password_header {
    font-family: Lato;
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    color: #576574;
    margin: 16px 0 20px 0;
}

.scheduler-forgot-password_card {
    display: flex;
    justify-content: center;
    padding: 32px 40px;
    width: 420px;
    margin-bottom: 16px;
}

.scheduler-forgot-password_message {
    font-size: 14px;
    text-align: center;
    font-weight: 300;
    color: #061c3f;
    padding: 0 32px;
}

.scheduler-forgot-password_content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.scheduler-forgot-password_content > :not(:first-child) {
    margin-top: 16px;
}

.scheduler-forgot-password_content > :last-child {
    margin-top: 24px;
}

.scheduler-forgot-password_link {
    color: #01b6f5;
    text-align: center;
    font-size: 1rem;
}

@media (max-width: 620px) {
    .scheduler-forgot-password_container {
        height: 100%;
        padding-top: 96px;
    }
    .scheduler-forgot-password_back-link {
        left: 16px;
        top: 16px;
    }
    .scheduler-forgot-password_card {
        width: 100%;
        border-radius: 0;
        padding: 20px;
        flex-direction: column;
    }
    .scheduler-forgot-password_content {
        border-left: 0;
        padding-left: 0;
        width: 100%;
    }
    .scheduler-forgot-password_header {
        font-size: 20px;
        margin: 8px 0 12px 0;
    }
}

.scheduler-reset-password-confirmation_container {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    margin: 0;
    padding: 0 16px;
}

.scheduler-reset-password-confirmation_title {
    margin: 24px 0 18px 0;
    font-family: Lato;
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    color: #576574;
}

.scheduler-reset-password-confirmation_content {
    font-family: Lato;
    font-size: 14px;
    line-height: 1.43;
    text-align: center;
    color: #061c3f;
}

.scheduler-reset-password-confirmation_user-mail {
    font-weight: 900;
    display: inline;
}

.scheduler-reset-password-confirmation_button {
    margin-top: 24px;
}

@media (max-width: 620px) {
    .scheduler-reset-password-confirmation_container {
        padding: 0 0 24px 0;
        width: 100%;
    }
}
.scheduler-terms-condition_container {
    height: 100vh;
    overflow: auto;
}

.scheduler-terms-condition_header {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    font-family: 'Lato-Light';
    font-size: 24px;
    font-weight: 300;
    color: #061c3f;
    height: 72px;
    border-bottom: 1px solid rgba(160, 160, 188, 0.1);
    box-shadow: 0 0px 1px 0 #e3e5ed;
    background-color: #ffffff;
}

.scheduler-terms-condition_content {
    font-family: 'Lato-Light';
    font-size: 16px;
    font-weight: 300;
    color: #061c3f;
    padding: 24px 30px;
    height: calc(100% - 110px);
    overflow: auto;
}

.scheduler-terms-condition_back-button {
    position: absolute;
    left: 16px;
    top: 16px;
}

@media (max-width: 620px) {
    .scheduler-terms-condition_back {
        position: absolute;
        left: 12px;
    }
    .scheduler-terms-condition_header {
        font-size: 18px;
    }
    .scheduler-terms-condition_content {
        font-size: 14px;
        padding: 24px 16px;
    }
}

.scheduler-privacy-policy_container {
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.scheduler-privacy-policy_header {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    font-family: 'Lato-Light';
    font-size: 24px;
    font-weight: 300;
    color: #061c3f;
    height: 72px;
    border-bottom: 1px solid rgba(160, 160, 188, 0.1);
    box-shadow: 0 0px 1px 0 #e3e5ed;
    background-color: #ffffff;
    position: relative;
}

.scheduler-privacy-policy_back-button {
    position: absolute;
    left: 16px;
    top: 16px;
}

.scheduler-privacy-policy_content {
    font-family: 'Lato-Light';
    font-size: 16px;
    font-weight: 300;
    color: #061c3f;
    padding: 24px 30px;
    height: calc(100% - 110px);
    overflow: auto;
}

.scheduler-privacy-policy_back {
    position: absolute;
    left: 30px;
}

.scheduler-privacy-policy_back-icon {
    margin-right: 8px;
}

@media (max-width: 620px) {
    .scheduler-privacy-policy_back {
        position: absolute;
        left: 12px;
    }
    .scheduler-privacy-policy_header {
        font-size: 18px;
    }
    .scheduler-privacy-policy_content {
        font-size: 14px;
        padding: 24px 16px;
    }
}

.scheduler-not-found_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url(/assets/images/not-found.svg);
    background-position: center;
    background-size: unset;
    background-repeat: no-repeat;
}

.scheduler-not-found_text {
    margin-top: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    text-align: center;
}

.scheduler-not-found_title {
    color: #bbbec1;
    font-weight: 300;
    margin-bottom: 8px;
}

.scheduler-not-found_link {
    color: #01B6F5;
    text-decoration: underline;
}

.scheduler-public_container {
    background-image: url(/assets/images/background-home.svg);
    background-position: center;
    background-size: contain;
    position: relative;
}

.scheduler-public_footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 12px;
    text-align: center;
    font-size: 12px;
    color: rgba(160, 160, 188, 0.5);
    background-color: #fCfdff;
}

@media (max-width: 750px) {
    .scheduler-public_container {
        height: 100vh;
    }
}

.schedule_language-selector {
    width: 110px;
}

.scheduler-top-bar_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 32px;
    background-color: #fff;
    box-shadow: 0 0 2px 0 #e3e5ed;
    width: 100%;
}

.scheduler-top-bar_content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.scheduler-top-bar_logo-container {
    display: flex;
    align-items: center;
}

.scheduler-top-bar_logo {
    margin-right: 16px;
    width: 48px;
}

.scheduler-top-bar_location-name {
    font-size: 16px;
    color: #576574;
    margin-top: 4px;
    font-weight: 300;
    line-height: 1.5;
}

.scheduler-top-bar_link-text {
    font-size: 18px;
    color: #061C3F;
    margin: 0 24px;
}

.scheduler-top-bar_link-text:nth-child(3) {
    margin-right: 52px;
}

.scheduler-top-bar_link-text:hover,
.scheduler-top-bar_link-text:active,
.scheduler-top-bar_link-text:focus {
    color: #01B6F5;
    text-decoration: none;
}

.scheduler-top-bar_link-text--active {
    color: #01B6F5;
    text-decoration: none;
}

.scheduler-top-bar_avatar-menu {
    margin-left: 24px;
}

.scheduler-top-bar_avatar-menu_user-details {
    padding: 0 12px;
    display: flex;
    align-items: center;
}

.scheduler-top-bar_avatar-icon {
    width: 65%;
    height: 65%;
    fill: white;
}

.scheduler-top-bar_avatar-menu_user-info {
    margin-left: 8px;
    width: -webkit-max-content;
    width: max-content;
}

.scheduler-top-bar_avatar-menu_user-name {
    font-size: 14px;
    color: #061c3f;
}

.scheduler-top-bar_avatar-menu_user-email {
    font-size: 12px;
    color: #a4a7b5;
}

.scheduler-top-bar_icon {
    fill: #061C3F;
    margin-bottom: 4px;
}

@media (max-width: 620px) {
    .scheduler-top-bar_container {
        padding: 12px 16px;
    }
    .scheduler-top-bar_avatar-menu {
        margin-left: 12px;
    }
}

.scheduler-confirmation-modal_button-cancel,
.scheduler-confirmation-modal_button-cancel:hover {
    color: #061c3f;
}

.scheduler-modal-confirmation_message-container {
    padding: 32px 24px 24px 24px;
    display: flex;
}

.scheduler-modal-confirmation_message-icon {
    width: 52px;
    height: 52px;
    fill: #fe4849;
    flex-shrink: 0;
    margin: 4px 16px 0 0;
}

.scheduler-modal-confirmation_message-title {
    font-size: 20px;
    font-weight: 800;
    line-height: 1.27;
    color: #061c3f;
    margin: 0 0 12px 0;
}

.scheduler-modal-confirmation_message-description {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    color: #a0a0bc;
}

.scheduler-global-modal_footer-container {
    box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.15);
    margin: -12px -16px 0 -16px;
    padding: 12px 12px 0 0;
    z-index: 10;
}


@media (max-width: 1224px) {
    .scheduler-global-modal_container {
        width: 100vw !important;
        height: 100vh !important;
        min-height: unset !important;
        max-height: unset !important;
        position: absolute;
        justify-content: flex-start;
        border-radius: 0 !important;
        padding: 16px 0 32px 0;
    }

    .scheduler-global-modal_container > div {
        height: 100% !important;
        padding: 0;
    }

    .scheduler-global-modal_container > button {
        top: 30px;
    }
}

.shedule-status-badge_container {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 0 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shedule-status-badge_hover-button {
    margin: 0 8px;
    flex-shrink: 1;
}

.shedule-status-badge_hover-button-icon {
    fill: #576574 !important;
    color: #576574 !important;
}

.shedule-status-badge_select-container {
    width: 100%;
    height: 100%;
    position: relative;

}

.shedule-status-badge_select-container::after {
    content: '';
    position: absolute;
    display: block;
    right: 1.5rem;
    bottom: 45%;
    pointer-events: none;
    width: 0.45rem;
    height: 0.45rem;
    border-style: solid;
    border-width: 0.15em 0.15em 0 0;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    vertical-align: top;
    color: #01b6f5 !important;
}

.shedule-status-badge_select {
    -moz-appearance: none;
    -webkit-appearance: none;
    width: calc(100% - 0.5rem);
    line-height: 1.5rem;
    height: 1.8rem;
    border: 1px solid #01b6f5 !important;
    border-radius: 1rem;
    padding: 0 0.675rem;
    background-color: white;
    color: #061c3f;
    font-size: 14px;
    transition: all 0.1s linear;
    position: relative;
}

.shedule-status-badge_select:focus, .shedule-status-badge_select:active, .shedule-status-badge_select:visited {
    outline: 0;
    border: 1px #01b6f5 solid;
    box-shadow: 0 0 2px #01b6f5;
}

.shedule-status-badge_icon {
    flex-shrink: 0;
    margin-bottom: 2px;
}

.shedule-status-badge_icon-own-car {
    width: 14px;
    margin-bottom: 2px;
}

.shedule-status-badge_icon-no-show {
    width: 14px;
    margin-bottom: 2px;
}

.shedule-status-badge_icon-sts {
    width: 14px;
    height: 14px;
    margin-bottom: 2px;
    fill: #009ACF !important;
    color: #009ACF !important;
}

.shedule-status-badge_processing {
    color: #576574 !important;
    padding-left: 6px !important;
    max-width: 120px;
}

.shedule-status-badge_verified {
    color: #1de9b6 !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
    max-width: 120px;
}

.shedule-status-badge_expired {
    color: #fe4849 !important;
    padding-right: 6px !important;
    padding-left: 8px !important;
    max-width: 120px;
}

.shedule-status-badge_own-car {
    color: #01B6F5 !important;
    padding-right: 6px !important;
    padding-left: 6px !important;
    max-width: 120px;
}

.shedule-status-badge_no-show {
    color: #a0a0bc !important;
    padding-right: 6px !important;
    padding-left: 6px !important;
    max-width: 120px;
}

.shedule-status-badge_sts {
    color: #009ACF !important;
    padding-right: 10px !important;
    padding-left: 6px !important;
}

.shedule-status-badge_kept-app {
    color: #5C56B6 !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    max-width: 120px;
}

.shedule-driver-cell_icon-sts {
    width: 14px;
    height: 14px;
    margin-bottom: 2px;
    fill: #009ACF !important;
    color: #009ACF !important;
}

.shedule-driver-cell_badge {
    color: #009ACF !important;
    padding-right: 8px !important;
    padding-left: 8px !important;
}

.scheduler-schedule_appointment-form-container {
    padding: 16px 16px;
}

.scheduler-schedule_appointment-form-input {
    width: 48%;
}

.scheduler-daily-schedule_wrap-container {
    padding: 32px 16px 16px 16px;
    background-color: white;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    overflow: auto;
}

.scheduler-daily-schedule_calendar-container {
    width: 340px;
    height: 410px;
    padding: 16px;
    margin-right: 12px;
    flex-grow: 0;
    flex-shrink: 0;
}

.scheduler-daily-schedule_table-card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
}

.scheduler-daily-schedule_table {
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
    background-color:#f4f6f9;
}

.scheduler-daily-schedule_table .rainbow-table_container {
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
}

.scheduler-daily-schedule_table-header {
    background-color: #f4f6f9;
    padding: 16px;
}

.scheduler-daily-schedule_table-header-content {
    display: flex;
    justify-content: space-between;
}

.scheduler-daily-schedule_table-title {
    font-size: 20px;
    color: #061C3F;
    font-weight: 700;
}

.scheduler-daily-schedule_table-subtitle {
    font-size: 14px;
    color: #576574;
    margin-top: 4px;
}

.scheduler-daily-schedule_actions-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 4px 0 0 48px;
    flex-grow: 1;
}

.scheduler-daily-schedule_search-input {
    margin-right: 12px;
    width: 260px;
}

.scheduler-daily-schedule_report-icon {
    height: 18px;
    width: 18px;
    margin-right: 8px;
}

.scheduler-daily-schedule_table-action-icon {
    fill: #061C3F;
    width: 14px;
    height: 14px;
    margin-bottom: 4px;
}

.scheduler-daily-schedule_regular-patients-table {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    box-sizing: border-box;
}

.scheduler-daily-schedule_regular-patients-table_footer {
    border-top: 0.0625px solid #e3e5ed;
    padding: 0.75rem 16px 0 16px;
    margin: 0 -16px
}

.scheduler-daily-schedule_regular-appointments-modal {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    box-sizing: border-box;
}

.scheduler-daily-schedule_regular-appointments-modal > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    box-sizing: border-box;
}

.scheduler-daily-schedule_regular-patients-container {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0 -16px;
}
.shedule-status-badge_icon {
    flex-shrink: 0;
    margin-bottom: 2px;
}

.shedule-status-badge_icon-own-car {
    width: 14px;
    margin-bottom: 2px;
}

.shedule-status-badge_icon-no-show {
    width: 14px;
    margin-bottom: 2px;
}

.shedule-status-badge_icon-sts {
    width: 14px;
    height: 14px;
    margin-bottom: 2px;
    fill: #009ACF !important;
    color: #009ACF !important;
}

.shedule-status-badge_processing {
    color: #576574 !important;
    padding-left: 6px !important;
    max-width: 120px;
}

.shedule-status-badge_verified {
    color: #1de9b6 !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
    max-width: 120px;
}

.shedule-status-badge_expired {
    color: #fe4849 !important;
    padding-right: 6px !important;
    padding-left: 8px !important;
    max-width: 120px;
}

.shedule-status-badge_own-car {
    color: #01B6F5 !important;
    padding-right: 6px !important;
    padding-left: 6px !important;
    max-width: 120px;
}

.shedule-status-badge_no-show {
    color: #a0a0bc !important;
    padding-right: 6px !important;
    padding-left: 6px !important;
    max-width: 120px;
}

.shedule-status-badge_sts {
    color: #009ACF !important;
    padding-right: 10px !important;
    padding-left: 6px !important;
}

.shedule-status-badge_kept-app {
    color: #5C56B6 !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    max-width: 120px;
}

.scheduler-appointment-card_container {
    background-color: #fcfcfc;
    padding: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.scheduler-appointment-card_header {
    display: flex;
    justify-content: space-between;
}

.scheduler-appointment-card_time {
    font-size: 14px;
    line-height: 23px;
    color: #a0a0bc;
    padding-right: 4px;
    text-align: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.scheduler-appointment-card_label {
    font-size: 12px;
    line-height: 1.5;
    font-weight: 500;
    color: #d2d2e0;
    text-transform: uppercase;
    margin-top: 8px;
    text-align: start;
}

.scheduler-appointment-card_text {
    font-size: 14px;
    line-height: 1.3;
    font-weight: 500;
    color: #a0a0bc;
    text-align: start;
}

.scheduler-appointment-card_hover-content {
    width: 100%;
    height: 100%;
    background-color: rgba(226, 233, 240, 0.7);
    transition: all 550ms linear;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.scheduler-appointment-card_hover-button-container {
    position: absolute;
    top: 8px;
    right: 8px;
}

.scheduler-appointment-card_hover-content-button-icon {
    fill: #576574;
    color: #576574;
}

.scheduler-weekly-schedule_wrap-container {
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    box-sizing: border-box;
}

.scheduler-weekly-schedule_container {
    padding: 24px 0 0 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
}

.scheduler-weekly-schedule_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 16px 0px 16px;
    background-color: white;
}

.scheduler-weekly-schedule_controlers {
    display: flex;
    align-items: center;
}

.scheduler-weekly-schedule_header-title {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.7px;
    color: #01b6f5;
    padding: 0 16px;
}

.scheduler-weekly-schedule_header-button {
    margin-left: 24px;
}

.scheduler-weekly-schedule_content {
    display: flex;
    height: 100%;
    width: 100%;
    overflow: auto;
    box-sizing: border-box;
    padding: 0 16px;
}

.scheduler-weekly-schedule_day-column {
    width: 16.6%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    box-sizing: border-box;
}

.scheduler-weekly-schedule_column-title {
    font-size: 14px;
    font-weight: 600;
    color: #576574;
    margin: 4px 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.scheduler-weekly-schedule_appointment-card-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 4px 8px 8px 8px;
}

.scheduler-weekly-schedule_appointment-card-container >:not(:last-child) {
    margin-bottom: 8px;
}

.scheduler-weekly-schedule_appointment-card-button {
    border-radius: 14px;
    width: 100%;
    margin: 0;
    padding: 0;
    outline: none;
    box-shadow: 0 0px 2px 0 transparent;
}

.scheduler-weekly-schedule_search {
    padding: 8px 12px;
    width: 100%;
}

.scheduler-weekly-schedule_search input {
    line-height: 2.2rem !important;
    height: 2.2rem !important;
    border-color: #e3e5ed;
}

.scheduler-daily-schedule_spinner-container {
    height: 100%;
    width: 100%;
    background-color: white;
}

@media (min-width: 1920px) {
    .scheduler-weekly-schedule_header, .scheduler-weekly-schedule_content {
        max-width: 1800px;
        margin: 0 auto;
    }
    
    .scheduler-weekly-schedule_day-column {
        width: 300px;
    }

    .scheduler-weekly-schedule_appointment-footer {
        display: flex;
        justify-content: center;
    }

    .scheduler-weekly-schedule_search {
        width: 300px;
    }
}

.scheduler-patients_patient-form-container {
    padding: 0 16px;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.scheduler-patients_patient-form-input {
    width: 49% !important;
}

.scheduler-patients_patient-form-input-small {
    width: 32%;
}

.scheduler-patients_patient-form-invalid-phone-label {
    display: flex;
    align-items: center;
}

.scheduler-patients_patient-form-warning-icon {
    width: 16px;
    height: 16px;
    color: #a77940;
    margin-right: 8px;
    flex-shrink: 0;
}

.scheduler-patients_patient-form-invalid-phone-help-text {
    color: #a77940;
    display: flex;
}
.scheduler-patients_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
}

.scheduler-patients_table-header {
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
}

.scheduler-patients_table-title {
    font-size: 20px;
    font-weight: 900;
    line-height: 1.5;
    color: #061c3f;
}

.scheduler-patients_table-subtitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: #576574;
    margin-top: 4px;
}

.scheduler-patients_table-header-actions {
    display: flex;
    align-items: center;
    margin-left: 16px;
}

.scheduler-patients_header-delete-button[disabled] svg {
    fill: #e3e5ed;
}

.scheduler-patients_search-input {
    width: 300px;
    margin-right: 12px;
}

.scheduler-patients_table {
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.scheduler-patients_table .rainbow-table_container {
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
}

.scheduler-patients_table-action-icon {
    fill: #061C3F;
    width: 14px;
    height: 14px;
    margin-bottom: 4px;
}

.scheduler-patients_report-icon {
    height: 18px;
    width: 18px;
    margin-right: 8px;
}

.scheduler-patients_name-button-container {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
}

.scheduler-patients_name-button {
    height: 28px;
    border: none;
    width: 100%;
    border-radius: 6px;
    color: rgb(42, 48, 57);
    line-height: 5px;
    text-decoration: none;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: #EEF1F5;
}

.scheduler-patients_name-button:hover {
    box-shadow: 0 1px 2px 0 #e3e5ed;
    background-color: red;
    background-color: #e4eaf1;
}

.scheduler-prospect_patient-form-container {
    padding: 0 16px;
    height: 100%;
}

.scheduler-prospect_patient-form-input {
    width: 49%;
}

.scheduler-prospects_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
}

.scheduler-prospects_table-header {
    padding: 12px 16px;
}

.scheduler-prospects_table-header-row-spread {
    display: flex;
    justify-content: space-between;
}

.scheduler-prospects_table-header-row {
    display: flex;
    margin-top: 12px;
}

.scheduler-prospects_table-title {
    font-size: 20px;
    font-weight: 900;
    line-height: 1.5;
    color: #061c3f;
}

.scheduler-prospects_table-subtitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: #576574;
    margin-top: 4px;
}

.scheduler-prospects_table-header-actions {
    display: flex;
    align-items: center;
    margin-left: 16px;
}

.scheduler-prospects_search-input {
    max-width: 380px;
    margin-right: 12px;
    flex-grow: 1;
}

.scheduler-prospects_users-filter {
    width: 250px !important;
}

.scheduler-prospects_time-filter {
    width: 250px !important;
}

.scheduler-prospects_table {
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.scheduler-prospects_table .rainbow-table_container {
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
}

.scheduler-prospects_table-action-icon {
    fill: #061C3F;
    width: 14px;
    height: 14px;
    margin-bottom: 4px;
}

.scheduler-prospects_report-icon {
    height: 18px;
    width: 18px;
    margin-right: 8px;
}

.scheduler-prospects_name-button-container {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
}

.scheduler-prospects_name-button {
    height: 28px;
    border: none;
    width: 100%;
    border-radius: 6px;
    color: rgb(42, 48, 57);
    line-height: 5px;
    text-decoration: none;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: #EEF1F5;
}

.scheduler-prospects_name-button:hover {
    box-shadow: 0 1px 2px 0 #e3e5ed;
    background-color: red;
    background-color: #e4eaf1;
}

.scheduler-profile_edit-profile-form_form-contaier {
    padding: 8px 16px;
}

.scheduler-profile_edit-profile-form_actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
}

.scheduler-profile_edit-profile-form_bottom-actions {
    margin: 0 12px;
}

.scheduler-profile_container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    overflow: auto;
    padding: 32px 32px 24px 32px;
}

.scheduler-profile_page-header {
    width: 100%;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
}

.scheduler-profile_header-title {
    font-size: 32px;
    font-weight: 400;
    color: #061c3f;
}

.scheduler-profile_content {
    width: 100%;
    display: flex;
    justify-content: space-between; 
}

.scheduler-profile_card-container {
    width: 49%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 12px 24px;
}

.scheduler-profile_card-container .rainbow-card_title {
    color: #01b6f5;
}

.scheduler-profile_card-personal-info-container {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px 24px 16px;
}

.scheduler-profile_card-personal-info {
    width: 65%;
    margin-right: 24px;
}

.scheduler-profile_personal-info-button {
    align-self: flex-end;
}

.scheduler-profile_personal-info-input {
    flex-grow: 1;
}

.scheduler-profile_avatar {
    width: 130px;
    height: 130px;
}

.scheduler-profile_avatar-icon {
    height: 45%;
    width: 45%;
    fill: white;
}

.scheduler-profile_avatar-label {
    text-align: center;
    font-size: 14px;
    color: #576574;
    margin-bottom: 12px;
}

.scheduler-profile_reauthenticate-form_title {
    padding: 8px 0;
    margin-top: -16px;
    font-size: 14px;
}

.scheduler-output_label {
    font-size: 13px;
    color: #576574;
    margin-bottom: 2px;
}

.scheduler-output_description {
    font-size: 15px;
    line-height: 1.4;
    color: #061C3F;
}

.shedule-status_icon-inprogress {
    margin-bottom: 2px;
    color: #576574 !important;
}

.shedule-status_icon-own-car {
    margin-bottom: 2px;
    color: #01B6F5 !important;
}

.shedule-status_icon-no-show {
    margin-bottom: 2px;
    color: #a0a0bc !important;
}

.shedule-status_icon-sts {
    width: 16px;
    height: 14px;
    margin-bottom: 2px !important;
    fill: #009ACF;
    color: #009ACF !important;
}
.scheduler-appointment_wrap-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    padding: 16px 32px 0 32px;
}

.scheduler-appointment_back-button {
    position: absolute;
    top: 20px;
    left: 16px;
}

.scheduler-appointment_card-content {
    border-top: 1px solid #E3E5ED;
    display: flex;
    padding: 16px 16px 0 16px;
    width: 100%;
}

.scheduler-appointment_link, .scheduler-appointment_link:hover {
    color: #01B6F5;
}

.scheduler-appointment_column {
    width: 50%;
    padding: 0 16px;
}

.scheduler-appointment_card-comment {
    width: 100%;
    padding: 0 32px 32px 32px;
}

.scheduler-appointment_timeline-container {
    width: 100%;
    padding: 16px;
}

.scheduler-appointment_not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(/assets/images/not-found.svg);
    background-position: center;
    background-size: unset;
    background-repeat: no-repeat;
    position: relative;
}

.scheduler-appointment_not-found-back-link {
    position: absolute;
    top: 24px;
}

.scheduler-appointment_not-found-text {
    margin-top: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    text-align: center;
}

.scheduler-appointment_not-found-title {
    color: #bbbec1;
    font-weight: 300;
    margin-bottom: 8px;
}

.scheduler-appointment_not-found-link {
    color: #01B6F5;
    text-decoration: underline;
}

.scheduler-patients-details-appointments_container {
    padding: 16px;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.scheduler-patients-details-appointments_radio-button {
    margin-bottom: 12px;
    align-self: flex-end;
}

.scheduler-patients-details-appointments_table-card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
}

.scheduler-patients-details-appointments_table-header {
    background-color: #f4f6f9;
    padding: 16px;
}

.scheduler-patients-details-appointments_table-header-content {
    display: flex;
    justify-content: space-between;
}

.scheduler-patients-details-appointments_table-title {
    font-size: 20px;
    color: #061C3F;
    font-weight: 700;
    line-height: 1;
}

.scheduler-patients-details-appointments_table-subtitle {
    font-size: 14px;
    color: #576574;
    margin-top: 4px;
}

.scheduler-patients-details-appointments_actions-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 0 0 48px;
    flex-grow: 1;
}

.scheduler-patients-details-appointments_search-input {
    margin-right: 12px;
    width: 360px;
}

.scheduler-patients-details-appointments_report-icon {
    height: 18px;
    width: 18px;
    margin-right: 8px;
}

.scheduler-patients-details-appointments_table-action-icon {
    fill: #061C3F;
    width: 14px;
    height: 14px;
    margin-bottom: 4px;
}

.scheduler-patients-details-appointments_table {
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
    background-color:#f4f6f9;
}

.scheduler-record-card_card-content {
    border-top: 1px solid #E3E5ED;
    padding: 16px;
    width: 100%;
}

.scheduler-record-card-actions_button-icon {
    fill: #01B6F5;
}

.scheduler-record-column-section_container {
    width: 50%;
    padding: 0 16px;
}
.scheduler-patient-details_content {
    padding: 16px;
    height: 100%;
    overflow: auto;
}

.scheduler-patient-details_card-content {
    border-top: 1px solid #E3E5ED;
    display: flex;
    padding: 16px 16px 0 16px;
    width: 100%;
}

.scheduler-patient-details_card-output-large {
    width: 100%;
    padding: 0 0 24px 0;
}

.scheduler-patient-details_output-undefined-value {
    color: #CFCFDC;
    font-weight: 400;
    font-style: italic;
}

.scheduler-patient-details_timeline-container {
    width: 100%;
    padding: 16px;
}

.scheduler-patients_patient-detail-invalid-phone-label {
    color: #a77940;
    font-size: 14px;
    display: flex;
    align-items: center;
}
.scheduler-patient-details_wrap-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    padding: 16px 0 0 0;
}

.scheduler-patient-details_back-button {
    margin-left: 8px;
}

.scheduler-patient-details_content-container {
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    padding: 0;
}

.scheduler-patient-details_timeline-content-container {
    height: 100%;
    overflow: auto;
    padding: 12px;
}

.scheduler-patient-details_timeline-note-label {
    font-size: 15px;
}

.scheduler-patient-details_timeline-note {
    border: 1px solid rgba(244, 246, 249, 1);
    background-color: rgba(244, 246, 249, 0.5);
    border-radius: 8px;
    padding: 8px 12px;
    margin-bottom: 24px;
    color: #000;
    font-size: 18px;
}

.scheduler-profile-member-form_container {
    padding: 0 16px 24px 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.scheduler-member-card_content {
    width: 100%;
    display: flex;
    padding: 0 12px;
    position: relative;
    border-radius: 16px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.11);
    background-color: #fff;
    margin-bottom: 2px;
}

.scheduler-member-card_user-info {
    display: flex;
    align-items: center;
    width: 35%;
    border-right: 1px solid #E9E9EC;
    padding: 8px 0;
}

.scheduler-member-card_photo {
    margin-right: 16px;
    flex-shrink: 0;
}

.scheduler-member-card_user-icon {
    height: 45%;
    width: 45%;
    fill: white;
}

.scheduler-member-card_info {
    padding-right: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.scheduler-member-card_primary-text {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.8;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.scheduler-member-card_permission-cell {
    display: flex;
    align-items: center;
    padding: 0 12px;
}

.scheduler-member-card_secondary-text {
    font-size: 13px;
    line-height: 1.2;
    color: #a0a0bc;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.scheduler-member-card_actions {
    position: absolute;
    right: 12px;
    border-left: 1px solid #E9E9EC;
    padding-left: 8px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scheduler-permissions_container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    overflow: auto;
    padding: 32px 32px 24px 32px;
}

.scheduler-permissions_page-header {
    width: 100%;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
}

.scheduler-permissions_header-title {
    font-size: 32px;
    font-weight: 400;
    color: #061c3f;
}

.scheduler-permissions_content {
    width: 100%;
    display: flex;
    justify-content: space-between; 
}

.scheduler-permissions_card-container {
    width: 49%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 12px 24px;
}

.scheduler-permissions_card-container .rainbow-card_title {
    color: #01b6f5;
}

.scheduler-permissions_card-personal-info-container {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px 24px 16px;
}

.scheduler-permissions_members-container {
    padding: 12px;
    width: 100%;
}

.scheduler-permissions_members-modal {
    width: 35%;
}

.scheduler-permissions_member-action-icon {
    fill: #01b6f5;
    width: 14px;
    height: 14px;
}

.scheduler-permissions_plus-icon {
    fill: #fff;
    margin-right: 12px;
}

.scheduler-profile_member-action-icon {
    fill:#061C3F;
    margin-bottom: 4px;
    width: 15px;
    height: 15px;
}

.scheduler-profile_member-action-more-icon {
    fill:#061C3F;
}

.scheduler-prospects_details-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    padding: 24px;
}
body, html {
    background-color: #fCfdff;
    height: 100%;
}

.scheduler-auth_wrap-container {
    background-color: #f4f6f9;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.scheduler-auth_content-container {
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
}

.scheduler-schedule_wrap-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
}

.scheduler-message_container {
    padding: 16px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    z-index: 9999999;
    -webkit-transform: translateY(-500%);
            transform: translateY(-500%);
    transition: opacity 250ms linear, -webkit-transform 550ms linear;
    transition: transform 550ms linear, opacity 250ms linear;
    transition: transform 550ms linear, opacity 250ms linear, -webkit-transform 550ms linear;
    box-shadow: 0 0 1px 0 #e3e5ed;
}

.scheduler-message_container-error {
    background-color: #fe4849;
}

.scheduler-message_container-success {
    background-color: #2ec4b6;
}

.scheduler-message_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}

.scheduler-message_text {
    color: #ffffff;
    padding: 0 16px;
    font-size: 16px;
    font-weight: 500;
    flex-grow: 1;
}

.scheduler-message_icon, .scheduler-message_close-button {
    flex-shrink: 0;
}

.scheduler-message_container--shown {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
}

