.scheduler-public_container {
    background-image: url(/assets/images/background-home.svg);
    background-position: center;
    background-size: contain;
    position: relative;
}

.scheduler-public_footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 12px;
    text-align: center;
    font-size: 12px;
    color: rgba(160, 160, 188, 0.5);
    background-color: #fCfdff;
}

@media (max-width: 750px) {
    .scheduler-public_container {
        height: 100vh;
    }
}
