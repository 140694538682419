.scheduler-member-card_content {
    width: 100%;
    display: flex;
    padding: 0 12px;
    position: relative;
    border-radius: 16px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.11);
    background-color: #fff;
    margin-bottom: 2px;
}

.scheduler-member-card_user-info {
    display: flex;
    align-items: center;
    width: 35%;
    border-right: 1px solid #E9E9EC;
    padding: 8px 0;
}

.scheduler-member-card_photo {
    margin-right: 16px;
    flex-shrink: 0;
}

.scheduler-member-card_user-icon {
    height: 45%;
    width: 45%;
    fill: white;
}

.scheduler-member-card_info {
    padding-right: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.scheduler-member-card_primary-text {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.8;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.scheduler-member-card_permission-cell {
    display: flex;
    align-items: center;
    padding: 0 12px;
}

.scheduler-member-card_secondary-text {
    font-size: 13px;
    line-height: 1.2;
    color: #a0a0bc;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.scheduler-member-card_actions {
    position: absolute;
    right: 12px;
    border-left: 1px solid #E9E9EC;
    padding-left: 8px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
