.scheduler-back-button {
    align-self: flex-start;
    margin-bottom: 12px;
}

.scheduler-back-button_icon {
    margin-right: 8px;
    width: 16px;
    height: 16px;
}
