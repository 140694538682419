.scheduler-patient-details_wrap-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    padding: 16px 0 0 0;
}

.scheduler-patient-details_back-button {
    margin-left: 8px;
}

.scheduler-patient-details_content-container {
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    padding: 0;
}

.scheduler-patient-details_timeline-content-container {
    height: 100%;
    overflow: auto;
    padding: 12px;
}

.scheduler-patient-details_timeline-note-label {
    font-size: 15px;
}

.scheduler-patient-details_timeline-note {
    border: 1px solid rgba(244, 246, 249, 1);
    background-color: rgba(244, 246, 249, 0.5);
    border-radius: 8px;
    padding: 8px 12px;
    margin-bottom: 24px;
    color: #000;
    font-size: 18px;
}
