.scheduler-patient-details_content {
    padding: 16px;
    height: 100%;
    overflow: auto;
}

.scheduler-patient-details_card-content {
    border-top: 1px solid #E3E5ED;
    display: flex;
    padding: 16px 16px 0 16px;
    width: 100%;
}

.scheduler-patient-details_card-output-large {
    width: 100%;
    padding: 0 0 24px 0;
}

.scheduler-patient-details_output-undefined-value {
    color: #CFCFDC;
    font-weight: 400;
    font-style: italic;
}

.scheduler-patient-details_timeline-container {
    width: 100%;
    padding: 16px;
}

.scheduler-patients_patient-detail-invalid-phone-label {
    color: #a77940;
    font-size: 14px;
    display: flex;
    align-items: center;
}