.shedule-status-badge_icon {
    flex-shrink: 0;
    margin-bottom: 2px;
}

.shedule-status-badge_icon-own-car {
    width: 14px;
    margin-bottom: 2px;
}

.shedule-status-badge_icon-no-show {
    width: 14px;
    margin-bottom: 2px;
}

.shedule-status-badge_icon-sts {
    width: 14px;
    height: 14px;
    margin-bottom: 2px;
    fill: #009ACF !important;
    color: #009ACF !important;
}

.shedule-status-badge_processing {
    color: #576574 !important;
    padding-left: 6px !important;
    max-width: 120px;
}

.shedule-status-badge_verified {
    color: #1de9b6 !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
    max-width: 120px;
}

.shedule-status-badge_expired {
    color: #fe4849 !important;
    padding-right: 6px !important;
    padding-left: 8px !important;
    max-width: 120px;
}

.shedule-status-badge_own-car {
    color: #01B6F5 !important;
    padding-right: 6px !important;
    padding-left: 6px !important;
    max-width: 120px;
}

.shedule-status-badge_no-show {
    color: #a0a0bc !important;
    padding-right: 6px !important;
    padding-left: 6px !important;
    max-width: 120px;
}

.shedule-status-badge_sts {
    color: #009ACF !important;
    padding-right: 10px !important;
    padding-left: 6px !important;
}

.shedule-status-badge_kept-app {
    color: #5C56B6 !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    max-width: 120px;
}
