.scheduler-profile_container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    overflow: auto;
    padding: 32px 32px 24px 32px;
}

.scheduler-profile_page-header {
    width: 100%;
    margin-bottom: 32px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.scheduler-profile_header-title {
    font-size: 32px;
    font-weight: 400;
    color: #061c3f;
}

.scheduler-profile_content {
    width: 100%;
    display: flex;
    justify-content: space-between; 
}

.scheduler-profile_card-container {
    width: 49%;
    height: fit-content;
    padding: 12px 24px;
}

.scheduler-profile_card-container .rainbow-card_title {
    color: #01b6f5;
}

.scheduler-profile_card-personal-info-container {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px 24px 16px;
}

.scheduler-profile_card-personal-info {
    width: 65%;
    margin-right: 24px;
}

.scheduler-profile_personal-info-button {
    align-self: flex-end;
}

.scheduler-profile_personal-info-input {
    flex-grow: 1;
}

.scheduler-profile_avatar {
    width: 130px;
    height: 130px;
}

.scheduler-profile_avatar-icon {
    height: 45%;
    width: 45%;
    fill: white;
}

.scheduler-profile_avatar-label {
    text-align: center;
    font-size: 14px;
    color: #576574;
    margin-bottom: 12px;
}

.scheduler-profile_reauthenticate-form_title {
    padding: 8px 0;
    margin-top: -16px;
    font-size: 14px;
}
