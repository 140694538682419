@media (max-width: 750px) {
    .scheduler-signup_card {
        width: 600px;
    }
}

@media (max-width: 620px) {
    .scheduler-signin_container {
        height: 100%;
        padding-top: 96px;
    }
    .scheduler-signin_card {
        width: 100%;
        border-radius: 0;
        padding: 20px;
        flex-direction: column;
    }
    .scheduler-signin_inputs-container {
        border-left: 0;
        padding-left: 0;
        width: 100%;
    }
    .scheduler-signin_header {
        font-size: 20px;
        margin: 8px 0 12px 0;
    }
}
