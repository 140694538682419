.shedule-status_icon-inprogress {
    margin-bottom: 2px;
    color: #576574 !important;
}

.shedule-status_icon-own-car {
    margin-bottom: 2px;
    color: #01B6F5 !important;
}

.shedule-status_icon-no-show {
    margin-bottom: 2px;
    color: #a0a0bc !important;
}

.shedule-status_icon-sts {
    width: 16px;
    height: 14px;
    margin-bottom: 2px !important;
    fill: #009ACF;
    color: #009ACF !important;
}