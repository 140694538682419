.scheduler-forgot-password_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 16px;
    padding-top: 16px;
    height: 100vh;
    position: relative;
}

.scheduler-forgot-password_back-button {
    position: absolute;
    top: 20px;
    left: 16px;
}

.scheduler-forgot-password_image {
    height: 54px;
    width: 54px;
}

.scheduler-forgot-password_header {
    font-family: Lato;
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    color: #576574;
    margin: 16px 0 20px 0;
}

.scheduler-forgot-password_card {
    display: flex;
    justify-content: center;
    padding: 32px 40px;
    width: 420px;
    margin-bottom: 16px;
}

.scheduler-forgot-password_message {
    font-size: 14px;
    text-align: center;
    font-weight: 300;
    color: #061c3f;
    padding: 0 32px;
}

.scheduler-forgot-password_content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.scheduler-forgot-password_content > :not(:first-child) {
    margin-top: 16px;
}

.scheduler-forgot-password_content > :last-child {
    margin-top: 24px;
}

.scheduler-forgot-password_link {
    color: #01b6f5;
    text-align: center;
    font-size: 1rem;
}
