.scheduler-profile_edit-profile-form_form-contaier {
    padding: 8px 16px;
}

.scheduler-profile_edit-profile-form_actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
}

.scheduler-profile_edit-profile-form_bottom-actions {
    margin: 0 12px;
}
