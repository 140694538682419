.scheduler-patients_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
}

.scheduler-patients_table-header {
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
}

.scheduler-patients_table-title {
    font-size: 20px;
    font-weight: 900;
    line-height: 1.5;
    color: #061c3f;
}

.scheduler-patients_table-subtitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: #576574;
    margin-top: 4px;
}

.scheduler-patients_table-header-actions {
    display: flex;
    align-items: center;
    margin-left: 16px;
}

.scheduler-patients_header-delete-button[disabled] svg {
    fill: #e3e5ed;
}

.scheduler-patients_search-input {
    width: 300px;
    margin-right: 12px;
}

.scheduler-patients_table {
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.scheduler-patients_table .rainbow-table_container {
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
}

.scheduler-patients_table-action-icon {
    fill: #061C3F;
    width: 14px;
    height: 14px;
    margin-bottom: 4px;
}

.scheduler-patients_report-icon {
    height: 18px;
    width: 18px;
    margin-right: 8px;
}

.scheduler-patients_name-button-container {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
}

.scheduler-patients_name-button {
    height: 28px;
    border: none;
    width: 100%;
    border-radius: 6px;
    color: rgb(42, 48, 57);
    line-height: 5px;
    text-decoration: none;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: #EEF1F5;
}

.scheduler-patients_name-button:hover {
    box-shadow: 0 1px 2px 0 #e3e5ed;
    background-color: red;
    background-color: #e4eaf1;
}
