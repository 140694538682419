.scheduler-prospects_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
}

.scheduler-prospects_table-header {
    padding: 12px 16px;
}

.scheduler-prospects_table-header-row-spread {
    display: flex;
    justify-content: space-between;
}

.scheduler-prospects_table-header-row {
    display: flex;
    margin-top: 12px;
}

.scheduler-prospects_table-title {
    font-size: 20px;
    font-weight: 900;
    line-height: 1.5;
    color: #061c3f;
}

.scheduler-prospects_table-subtitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: #576574;
    margin-top: 4px;
}

.scheduler-prospects_table-header-actions {
    display: flex;
    align-items: center;
    margin-left: 16px;
}

.scheduler-prospects_search-input {
    max-width: 380px;
    margin-right: 12px;
    flex-grow: 1;
}

.scheduler-prospects_users-filter {
    width: 250px !important;
}

.scheduler-prospects_time-filter {
    width: 250px !important;
}

.scheduler-prospects_table {
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.scheduler-prospects_table .rainbow-table_container {
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
}

.scheduler-prospects_table-action-icon {
    fill: #061C3F;
    width: 14px;
    height: 14px;
    margin-bottom: 4px;
}

.scheduler-prospects_report-icon {
    height: 18px;
    width: 18px;
    margin-right: 8px;
}

.scheduler-prospects_name-button-container {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
}

.scheduler-prospects_name-button {
    height: 28px;
    border: none;
    width: 100%;
    border-radius: 6px;
    color: rgb(42, 48, 57);
    line-height: 5px;
    text-decoration: none;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: #EEF1F5;
}

.scheduler-prospects_name-button:hover {
    box-shadow: 0 1px 2px 0 #e3e5ed;
    background-color: red;
    background-color: #e4eaf1;
}
