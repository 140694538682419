.scheduler-weekly-schedule_wrap-container {
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    box-sizing: border-box;
}

.scheduler-weekly-schedule_container {
    padding: 24px 0 0 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
}

.scheduler-weekly-schedule_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 16px 0px 16px;
    background-color: white;
}

.scheduler-weekly-schedule_controlers {
    display: flex;
    align-items: center;
}

.scheduler-weekly-schedule_header-title {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.7px;
    color: #01b6f5;
    padding: 0 16px;
}

.scheduler-weekly-schedule_header-button {
    margin-left: 24px;
}

.scheduler-weekly-schedule_content {
    display: flex;
    height: 100%;
    width: 100%;
    overflow: auto;
    box-sizing: border-box;
    padding: 0 16px;
}

.scheduler-weekly-schedule_day-column {
    width: 16.6%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    box-sizing: border-box;
}

.scheduler-weekly-schedule_column-title {
    font-size: 14px;
    font-weight: 600;
    color: #576574;
    margin: 4px 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.scheduler-weekly-schedule_appointment-card-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 4px 8px 8px 8px;
}

.scheduler-weekly-schedule_appointment-card-container >:not(:last-child) {
    margin-bottom: 8px;
}

.scheduler-weekly-schedule_appointment-card-button {
    border-radius: 14px;
    width: 100%;
    margin: 0;
    padding: 0;
    outline: none;
    box-shadow: 0 0px 2px 0 transparent;
}

.scheduler-weekly-schedule_search {
    padding: 8px 12px;
    width: 100%;
}

.scheduler-weekly-schedule_search input {
    line-height: 2.2rem !important;
    height: 2.2rem !important;
    border-color: #e3e5ed;
}

.scheduler-daily-schedule_spinner-container {
    height: 100%;
    width: 100%;
    background-color: white;
}
