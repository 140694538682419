.scheduler-global-modal_footer-container {
    box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.15);
    margin: -12px -16px 0 -16px;
    padding: 12px 12px 0 0;
    z-index: 10;
}


@media (max-width: 1224px) {
    .scheduler-global-modal_container {
        width: 100vw !important;
        height: 100vh !important;
        min-height: unset !important;
        max-height: unset !important;
        position: absolute;
        justify-content: flex-start;
        border-radius: 0 !important;
        padding: 16px 0 32px 0;
    }

    .scheduler-global-modal_container > div {
        height: 100% !important;
        padding: 0;
    }

    .scheduler-global-modal_container > button {
        top: 30px;
    }
}
