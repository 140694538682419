.scheduler-patients-details-appointments_container {
    padding: 16px;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.scheduler-patients-details-appointments_radio-button {
    margin-bottom: 12px;
    align-self: flex-end;
}

.scheduler-patients-details-appointments_table-card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
}

.scheduler-patients-details-appointments_table-header {
    background-color: #f4f6f9;
    padding: 16px;
}

.scheduler-patients-details-appointments_table-header-content {
    display: flex;
    justify-content: space-between;
}

.scheduler-patients-details-appointments_table-title {
    font-size: 20px;
    color: #061C3F;
    font-weight: 700;
    line-height: 1;
}

.scheduler-patients-details-appointments_table-subtitle {
    font-size: 14px;
    color: #576574;
    margin-top: 4px;
}

.scheduler-patients-details-appointments_actions-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 0 0 48px;
    flex-grow: 1;
}

.scheduler-patients-details-appointments_search-input {
    margin-right: 12px;
    width: 360px;
}

.scheduler-patients-details-appointments_report-icon {
    height: 18px;
    width: 18px;
    margin-right: 8px;
}

.scheduler-patients-details-appointments_table-action-icon {
    fill: #061C3F;
    width: 14px;
    height: 14px;
    margin-bottom: 4px;
}

.scheduler-patients-details-appointments_table {
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
    background-color:#f4f6f9;
}
