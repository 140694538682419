.scheduler-message_container {
    padding: 16px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    z-index: 9999999;
    transform: translateY(-500%);
    transition: transform 550ms linear, opacity 250ms linear;
    box-shadow: 0 0 1px 0 #e3e5ed;
}

.scheduler-message_container-error {
    background-color: #fe4849;
}

.scheduler-message_container-success {
    background-color: #2ec4b6;
}

.scheduler-message_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}

.scheduler-message_text {
    color: #ffffff;
    padding: 0 16px;
    font-size: 16px;
    font-weight: 500;
    flex-grow: 1;
}

.scheduler-message_icon, .scheduler-message_close-button {
    flex-shrink: 0;
}

.scheduler-message_container--shown {
    transform: translateY(0%);
}
