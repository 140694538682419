.scheduler-permissions_container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    overflow: auto;
    padding: 32px 32px 24px 32px;
}

.scheduler-permissions_page-header {
    width: 100%;
    margin-bottom: 32px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.scheduler-permissions_header-title {
    font-size: 32px;
    font-weight: 400;
    color: #061c3f;
}

.scheduler-permissions_content {
    width: 100%;
    display: flex;
    justify-content: space-between; 
}

.scheduler-permissions_card-container {
    width: 49%;
    height: fit-content;
    padding: 12px 24px;
}

.scheduler-permissions_card-container .rainbow-card_title {
    color: #01b6f5;
}

.scheduler-permissions_card-personal-info-container {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px 24px 16px;
}

.scheduler-permissions_members-container {
    padding: 12px;
    width: 100%;
}

.scheduler-permissions_members-modal {
    width: 35%;
}

.scheduler-permissions_member-action-icon {
    fill: #01b6f5;
    width: 14px;
    height: 14px;
}

.scheduler-permissions_plus-icon {
    fill: #fff;
    margin-right: 12px;
}

.scheduler-profile_member-action-icon {
    fill:#061C3F;
    margin-bottom: 4px;
    width: 15px;
    height: 15px;
}

.scheduler-profile_member-action-more-icon {
    fill:#061C3F;
}