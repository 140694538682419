.scheduler-daily-schedule_wrap-container {
    padding: 32px 16px 16px 16px;
    background-color: white;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    overflow: auto;
}

.scheduler-daily-schedule_calendar-container {
    width: 340px;
    height: 410px;
    padding: 16px;
    margin-right: 12px;
    flex-grow: 0;
    flex-shrink: 0;
}

.scheduler-daily-schedule_table-card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
}

.scheduler-daily-schedule_table {
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
    background-color:#f4f6f9;
}

.scheduler-daily-schedule_table .rainbow-table_container {
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
}

.scheduler-daily-schedule_table-header {
    background-color: #f4f6f9;
    padding: 16px;
}

.scheduler-daily-schedule_table-header-content {
    display: flex;
    justify-content: space-between;
}

.scheduler-daily-schedule_table-title {
    font-size: 20px;
    color: #061C3F;
    font-weight: 700;
}

.scheduler-daily-schedule_table-subtitle {
    font-size: 14px;
    color: #576574;
    margin-top: 4px;
}

.scheduler-daily-schedule_actions-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 4px 0 0 48px;
    flex-grow: 1;
}

.scheduler-daily-schedule_search-input {
    margin-right: 12px;
    width: 260px;
}

.scheduler-daily-schedule_report-icon {
    height: 18px;
    width: 18px;
    margin-right: 8px;
}

.scheduler-daily-schedule_table-action-icon {
    fill: #061C3F;
    width: 14px;
    height: 14px;
    margin-bottom: 4px;
}

.scheduler-daily-schedule_regular-patients-table {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    box-sizing: border-box;
}

.scheduler-daily-schedule_regular-patients-table_footer {
    border-top: 0.0625px solid #e3e5ed;
    padding: 0.75rem 16px 0 16px;
    margin: 0 -16px
}

.scheduler-daily-schedule_regular-appointments-modal {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    box-sizing: border-box;
}

.scheduler-daily-schedule_regular-appointments-modal > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    box-sizing: border-box;
}

.scheduler-daily-schedule_regular-patients-container {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0 -16px;
}