.scheduler-confirmation-modal_button-cancel,
.scheduler-confirmation-modal_button-cancel:hover {
    color: #061c3f;
}

.scheduler-modal-confirmation_message-container {
    padding: 32px 24px 24px 24px;
    display: flex;
}

.scheduler-modal-confirmation_message-icon {
    width: 52px;
    height: 52px;
    fill: #fe4849;
    flex-shrink: 0;
    margin: 4px 16px 0 0;
}

.scheduler-modal-confirmation_message-title {
    font-size: 20px;
    font-weight: 800;
    line-height: 1.27;
    color: #061c3f;
    margin: 0 0 12px 0;
}

.scheduler-modal-confirmation_message-description {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    color: #a0a0bc;
}
