.scheduler-top-bar_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 32px;
    background-color: #fff;
    box-shadow: 0 0 2px 0 #e3e5ed;
    width: 100%;
}

.scheduler-top-bar_content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.scheduler-top-bar_logo-container {
    display: flex;
    align-items: center;
}

.scheduler-top-bar_logo {
    margin-right: 16px;
    width: 48px;
}

.scheduler-top-bar_location-name {
    font-size: 16px;
    color: #576574;
    margin-top: 4px;
    font-weight: 300;
    line-height: 1.5;
}

.scheduler-top-bar_link-text {
    font-size: 18px;
    color: #061C3F;
    margin: 0 24px;
}

.scheduler-top-bar_link-text:nth-child(3) {
    margin-right: 52px;
}

.scheduler-top-bar_link-text:hover,
.scheduler-top-bar_link-text:active,
.scheduler-top-bar_link-text:focus {
    color: #01B6F5;
    text-decoration: none;
}

.scheduler-top-bar_link-text--active {
    color: #01B6F5;
    text-decoration: none;
}

.scheduler-top-bar_avatar-menu {
    margin-left: 24px;
}

.scheduler-top-bar_avatar-menu_user-details {
    padding: 0 12px;
    display: flex;
    align-items: center;
}

.scheduler-top-bar_avatar-icon {
    width: 65%;
    height: 65%;
    fill: white;
}

.scheduler-top-bar_avatar-menu_user-info {
    margin-left: 8px;
    width: max-content;
}

.scheduler-top-bar_avatar-menu_user-name {
    font-size: 14px;
    color: #061c3f;
}

.scheduler-top-bar_avatar-menu_user-email {
    font-size: 12px;
    color: #a4a7b5;
}

.scheduler-top-bar_icon {
    fill: #061C3F;
    margin-bottom: 4px;
}

@media (max-width: 620px) {
    .scheduler-top-bar_container {
        padding: 12px 16px;
    }
    .scheduler-top-bar_avatar-menu {
        margin-left: 12px;
    }
}
