.scheduler-signin_container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
    padding-top: 40px;
    height: 100vh;
}

.scheduler-signin_logo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scheduler-signin_image {
    height: 64px;
}

.scheduler-signin_header {
    font-family: Lato;
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    color: #061C3F;
    margin: 14px 0 0 0;
}

.scheduler-signin_location-name {
    font-size: 16px;
    color: #576574;
    margin: 4px 0 12px 0;
    font-weight: 300;
    line-height: 1.5;
}

.scheduler-signin_card {
    display: flex;
    align-items: center;
    padding: 32px 40px;
    width: 420px;
    margin-bottom: 16px;
    height: max-content;
    min-height: 320px;
}

.scheduler-signin_inputs-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.scheduler-signin_inputs-container > :not(:first-child) {
    margin-top: 24px;
}

.scheduler-signin_inputs-container > :last-child {
    margin-top: 16px;
}

.scheduler-signin_link {
    color: #01b6f5;
    text-align: center;
    font-size: 1rem;
}

.scheduler-signin_language-selector {
    position: absolute;
    top: 20px;
    right: 20px;
}
