.scheduler-appointment_wrap-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    padding: 16px 32px 0 32px;
}

.scheduler-appointment_back-button {
    position: absolute;
    top: 20px;
    left: 16px;
}

.scheduler-appointment_card-content {
    border-top: 1px solid #E3E5ED;
    display: flex;
    padding: 16px 16px 0 16px;
    width: 100%;
}

.scheduler-appointment_link, .scheduler-appointment_link:hover {
    color: #01B6F5;
}

.scheduler-appointment_column {
    width: 50%;
    padding: 0 16px;
}

.scheduler-appointment_card-comment {
    width: 100%;
    padding: 0 32px 32px 32px;
}

.scheduler-appointment_timeline-container {
    width: 100%;
    padding: 16px;
}

.scheduler-appointment_not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(/assets/images/not-found.svg);
    background-position: center;
    background-size: unset;
    background-repeat: no-repeat;
    position: relative;
}

.scheduler-appointment_not-found-back-link {
    position: absolute;
    top: 24px;
}

.scheduler-appointment_not-found-text {
    margin-top: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    text-align: center;
}

.scheduler-appointment_not-found-title {
    color: #bbbec1;
    font-weight: 300;
    margin-bottom: 8px;
}

.scheduler-appointment_not-found-link {
    color: #01B6F5;
    text-decoration: underline;
}
