.scheduler-appointment-card_container {
    background-color: #fcfcfc;
    padding: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.scheduler-appointment-card_header {
    display: flex;
    justify-content: space-between;
}

.scheduler-appointment-card_time {
    font-size: 14px;
    line-height: 23px;
    color: #a0a0bc;
    padding-right: 4px;
    text-align: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.scheduler-appointment-card_label {
    font-size: 12px;
    line-height: 1.5;
    font-weight: 500;
    color: #d2d2e0;
    text-transform: uppercase;
    margin-top: 8px;
    text-align: start;
}

.scheduler-appointment-card_text {
    font-size: 14px;
    line-height: 1.3;
    font-weight: 500;
    color: #a0a0bc;
    text-align: start;
}

.scheduler-appointment-card_hover-content {
    width: 100%;
    height: 100%;
    background-color: rgba(226, 233, 240, 0.7);
    transition: all 550ms linear;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.scheduler-appointment-card_hover-button-container {
    position: absolute;
    top: 8px;
    right: 8px;
}

.scheduler-appointment-card_hover-content-button-icon {
    fill: #576574;
    color: #576574;
}
